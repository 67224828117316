@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

body {
  margin: 0;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.input-radio input {
  accent-color: #0d7385;
  min-width: 18px;
  height: 18px;
  margin: 0;
  margin-right: 8px;
  position: absolute;
  opacity: 0;
}

.input-radio p {
  margin-top: -2.5px;
}

.input-radio ul {
  margin: 0;
  justify-content: center;
}

.input-radio ul li {
  width: 50px;
  height: 25px;
  font-weight: 700;
  display: none;
}

.input-radio input:not(:checked) ~ div ul li:first-child {
  display: block;
}

.input-radio input:checked ~ div ul li:last-child {
  display: block;
}

.input-radio .switcher {
  width: 45px;
  height: 20px;
  background-color: #fff;
  margin-right: 7.5px;
  position: relative;
  transition: ease 0.3s all;
}

.input-radio .switcher span {
  width: 14px;
  height: 14px;
  position: absolute;
  border-radius: 18px;
  top: 0;
  bottom: 0;
  left: 2px;
  margin: auto;
  transition: ease 0.3s all;
}

.input-radio input:checked ~ div .switcher {
  background-color: rgb(51 65 85 / 1);
}

.input-radio input:checked ~ div .switcher span {
  right: 0;
  left: calc(100% - 16px);
  background-color: #fff;
}

.btn-site {
  background-color: rgb(51 65 85 / 1);
  border-radius: 5px;
  color: #fff;
  padding: 5px 20px;
  font-weight: 500;
  transition: ease 0.3s all;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.btn-site:hover {
  filter: brightness(0.9);
}

.btn-site.secondary {
  background-color: rgb(51 65 85 / 0.2);
  color: rgb(51 65 85 / 1);
}

.input-text p:first-of-type {
  color: rgb(51 65 85 / 1);
}

.input-text input {
  border: 1px solid #0d7385;
  width: 100%;
  padding: 5px 7px;
  margin-top: 6px;
  border-radius: 3px;
}

.input-text input:focus {
  outline: none;
  box-shadow: 0 0 3px rgb(51 65 85 / 1);
}

.radio-wrapper .input-description {
  margin: 1rem 0;
}

.input-radio-unique {
  position: relative;
  margin-bottom: 8px;
}

.input-radio-unique input {
  position: absolute;
  opacity: 0;
}

.input-radio-unique span.icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: 2px solid rgb(51 65 85 / 1);
  border-radius: 20px;
  margin-right: 5px;
  position: relative;
}

.input-radio-unique span.icon:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
  inset: 0 0 0 0;
  margin: auto;
  transition: ease 0.3s all;
}

.input-radio-unique input:checked ~ span.icon:after {
  background-color: rgb(51 65 85 / 1);
}

.input-radio-unique span:not(.icon) {
  font-weight: 700;
  margin-top: -2px;
}

#response-list {
  margin: 1rem 0;
}

#response-list li {
  list-style-type: disc;
  margin-left: 1.5rem;
}
